import { floatToDate, getEndOfMonth, getEndOfYear, matchesDate } from '@/parse-and-play/utils.js'

class Filter {
  constructor (appliesTo, type, value) {
    this.appliesTo = appliesTo
    this.type = type
    this.value = value
  }

  toString () {
    return [this.type, `"${this.value}"`].join(':')
  }
}

Filter.APPLIES_TO = {
  TRANSACTION: 'transaction',
  POSTING: 'posting',
  UNKNOWN: 'unknown'
}

class DateFilter extends Filter {
  constructor (period, value) {
    super(Filter.APPLIES_TO.TRANSACTION, 'date', value)
    this.period = period
  }

  filter () {
    if (this.hasRange) {
      return (transaction) => matchesDate(floatToDate(transaction.date), this.startAt, this.endAt)
    } else {
      return () => true
    }
  }

  get startAt () {
    if (this.hasRange) {
      return this.value
    }

    return null
  }

  get endAt () {
    if (this.period === DateFilter.PERIOD.MONTH) {
      return getEndOfMonth(this.value)
    } else if (this.period === DateFilter.PERIOD.YEAR) {
      return getEndOfYear(this.value)
    }

    return null
  }

  get hasRange () {
    return this.period === DateFilter.PERIOD.MONTH || this.period === DateFilter.PERIOD.YEAR
  }

  toString () {
    const str = ['date', this.period]
    if (this.value) str.push(this.value)
    return str.join(':')
  }
}

DateFilter.PERIOD = {
  MONTH: 'month',
  YEAR: 'year',
  ALL: 'all'
}

DateFilter.thisYear = () => {
  const year = new Date().getFullYear()
  return new DateFilter(DateFilter.PERIOD.YEAR, new Date(year, 0))
}

DateFilter.thisMonth = () => {
  const year = new Date().getFullYear()
  const month = new Date().getMonth()
  return new DateFilter(DateFilter.PERIOD.MONTH, new Date(year, month))
}

DateFilter.all = () => {
  return new DateFilter(DateFilter.PERIOD.ALL)
}

class TypeFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.UNKNOWN, 'type', value)
  }
}

class ScenarioFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.UNKNOWN, 'scenario', value)
  }
}

class AccountFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.POSTING, 'account', value)
  }

  filter () {
    if (this.value.toString().indexOf('*') !== -1) {
      const value = new RegExp(this.value.replace('*', '(.+)?'))

      return (posting) => posting.account.match(value)
    } else {
      return (posting) => posting.account.indexOf(this.value) === 0
    }
  }
}

class BudgetFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.TRANSACTION, 'budget', value)
  }

  filter () {
    if (this.value === 'false' || this.value === 'none') {
      return (transaction) => !transaction.periodicName
    } else if (this.value === 'true' || this.value === 'all') {
      return () => true
    } else {
      return (transaction) => !transaction.periodicName || transaction.periodicName === this.value
    }
  }
}

class CurrencyFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.POSTING, 'currency', value)
  }

  filter () {
    return (posting) => posting.currency === this.value
  }
}

class PayeeFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.TRANSACTION, 'payee', value)
  }

  filter () {
    if (this.value.toString().indexOf('*') !== -1) {
      const value = new RegExp(this.value.replace('*', '(.+)?'))

      return (transaction) => transaction.payee.match(value)
    } else {
      return (transaction) => transaction.payee === this.value
    }
  }
}

class ClearedFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.TRANSACTION, 'cleared', value)
  }

  filter () {
    const value = this.value.toString()

    return (transaction) => transaction.cleared.toString() === value
  }
}

class HasTxnIdFilter extends Filter {
  constructor (value) {
    super(Filter.APPLIES_TO.TRANSACTION, 'hasTxnId', value)
  }

  filter () {
    const value = this.value.toString()

    return (transaction) => (transaction.referenceId ? 'true' : 'false') === value
  }
}

Filter.fromObj = (obj) => {
  switch (obj.type) {
    case 'account':
      return new AccountFilter(obj.value)
    case 'currency':
      return new CurrencyFilter(obj.value)
    case 'date':
      return new DateFilter(obj.period, new Date(obj.value))
    case 'scenario':
      return new ScenarioFilter(obj.value)
    case 'cleared':
      return new ClearedFilter(obj.value)
    case 'payee':
      return new PayeeFilter(obj.value)
    case 'budget':
      return new BudgetFilter(obj.value)
    case 'hasTxnId':
      return new HasTxnIdFilter(obj.value)
    case 'type':
      return new TypeFilter(obj.value)
  }
}

export {
  DateFilter,
  TypeFilter,
  ScenarioFilter,
  AccountFilter,
  CurrencyFilter,
  PayeeFilter,
  ClearedFilter,
  BudgetFilter,
  HasTxnIdFilter,
  Filter
}
