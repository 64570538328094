<template>
  <Dropdown :is-inline="true">
    <template #prompt>
      <svg
        class="inline-block h-7 w-7"
        fill="none"
        viewBox="0 0 28 28"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </template>
    <template #content="props">
      <div
        class="text-sm divide-y divide-neutral-300 dark:divide-neutral-800"
        @click="props.close"
      >
        <div class="py-2">
          <p class="px-4 pt-2 pb-1 text-xs uppercase text-neutral-400 dark:text-neutral-700">
            {{ app.identity.name }}
          </p>
          <router-link
            :to="{ name: 'sources', params: { identity_id: app.identity.id } }"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
          >
            Data Sources
          </router-link>
          <!-- <router-link
            :to="{ name: 'scenarios', params: { identity_id: app.identity.id } }"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
          >
            Scenarios
          </router-link> -->
          <router-link
            :to="{ name: 'settings', params: { identity_id: app.identity.id } }"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
          >
            Settings
          </router-link>
          <router-link
            :to="{ name: 'export', params: { identity_id: app.identity.id } }"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
          >
            Export
          </router-link>
        </div>
        <div class="py-2">
          <p class="px-4 pt-2 pb-1 text-xs uppercase text-neutral-400 dark:text-neutral-700">
            Identities
          </p>
          <router-link
            v-for="identity in identities"
            :key="`choose-identity-${identity.id}`"
            :to="{ name: 'transactions', params: { identity_id: identity.id } }"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
          >
            {{ identity.name }}
          </router-link>
          <a
            href="javascript:;"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
            @click="addIdentity"
          >
            + Add identity
          </a>
        </div>
        <div class="py-2">
          <p class="px-4 pt-2 pb-1 text-xs uppercase text-neutral-400 dark:text-neutral-700">
            FastFinance
          </p>
          <router-link
            :to="{ name: 'about', params: { identity_id: app.identity.id } }"
            class="text-neutral-700 dark:text-neutral-400 block px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-950"
          >
            About FastFinance
          </router-link>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@/app/components/dropdown/component.vue'

export default {
  components: {
    Dropdown
  },

  props: ['app'],

  computed: {
    identities () {
      return this.app.queries.findAllIdentities()
        .filter((i) => i.id !== this.app.identity.id)
    }
  },

  methods: {
    addIdentity () {
      this.app.commands.addIdentity({ name: `Dunder Mifflin #${this.identities.length + 2}` })

      const id = this.identities[this.identities.length - 1].id

      this.$router
        .push({ name: 'transactions', params: { identity_id: id } })
        .then(() => this.$router.push({ name: 'settings', params: { identity_id: id } }))
    }
  }
}
</script>
