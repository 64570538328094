class Account {
  constructor (attrs) {
    this.id = attrs.id
    this.name = attrs.name
    this.address = attrs.address
    this.identityId = attrs.identityId
  }
}

export default Account
