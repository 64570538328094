<template>
  <div ref="chartWrapper" />
</template>

<script>
import Chart from 'chart.js/auto'
import { getStartOfMonth, getEndOfYear, getStartOfYear, floatToDate, floatToDateString, getEndOfMonth, matchesDate, sortByKey } from '@/parse-and-play/utils.js'

export default {
  props: ['transactions', 'date'],
  computed: {
    chartData () {
      const dates = []
      const totals = {}
      const obj = { dates, totals }
      const transactions = this.transactions

      if (transactions.length <= 1) {
        return obj
      }

      const firstTransactionDate = floatToDate(this.transactions[0].date)
      const startAt = getStartOfYear(firstTransactionDate)
      let endAt = floatToDate(this.transactions[this.transactions.length - 1].date)
      endAt = getEndOfYear(endAt)

      const date = new Date(startAt)
      const endAtTime = endAt.getTime()
      const currencies = transactions.reduce((arr, transaction) => {
        transaction.postings.forEach((posting) => {
          if (arr.indexOf(posting.currency) === -1) {
            arr.push(posting.currency)
          }
        })

        return arr
      }, [])

      currencies.forEach((currency) => {
        totals[currency] = []
      })

      while (date.getTime() < endAtTime) {
        const startOfMonth = getStartOfMonth(date)
        const endOfMonth = getEndOfMonth(date)
        const monthStr = date.toLocaleDateString('en-us', { year: 'numeric', month: 'short' })
        const monthlyTotals = {}

        dates.push(monthStr)

        currencies.forEach((currency) => {
          monthlyTotals[currency] = 0
        })

        transactions
          .filter((t) => matchesDate(new Date(floatToDateString(t.date)).getTime(), startOfMonth.getTime(), endOfMonth.getTime()))
          .forEach((transaction) => {
            transaction.postings.forEach((posting) => {
              monthlyTotals[posting.currency] += posting.amount
            })
          })

        currencies.forEach((currency) => {
          totals[currency].push(monthlyTotals[currency] || 0)
        })

        date.setMonth(date.getMonth() + 1)
      }

      for (const currency in totals) {
        const total = totals[currency]
        const newTotal = []

        for (var i = 1; i <= total.length; i++) {
          newTotal.push(total.slice(0, i).reduce((acc, n) => acc + n, 0))
        }

        totals[currency] = newTotal
      }

      return obj
    }
  },
  watch: {
    chartData () {
      this.$nextTick(() => this.makeChart())
    }
  },
  mounted () {
    this.makeChart()
  },
  methods: {
    makeChart () {
      const data = this.chartData
      const labels = data.dates
      const canvas = document.createElement('canvas')

      canvas.className = 'block mx-auto w-full h-32'

      this.$refs.chartWrapper.innerHTML = ''
      this.$refs.chartWrapper.appendChild(canvas)

      const datasets = []
      const scales = {}
      const totals = sortByKey(data.totals)
      let i = 0

      for (const currency in totals) {
        const isSimple = false // [...new Set(totals[currency])].length > 20

        datasets.push({
          label: currency,
          data: totals[currency],
          borderWidth: 3,
          yAxisID: `y${i}`
        })

        scales[`y${i}`] = {
          stackWeight: isSimple ? 10 : 1
        }

        i += 1
      }

      // eslint-disable-next-line
      new Chart(canvas, {
        type: 'line',
        data: {
          labels,
          datasets
        },
        options: {
          responsive: true,
          animation: {
            duration: 0
          },
          scales
        }
      })
    }
  }
}
</script>
