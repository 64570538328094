import { floatToDateString, formatAmount, pad } from '@/parse-and-play/utils.js'

class PlainText {
  constructor (accounts = [], transactions = [], periodics = [], comments = []) {
    this.accounts = accounts
    this.transactions = transactions
    this.periodics = periodics
    this.comments = comments
  }

  toString () {
    const lines = []
    const largestAccountLength = this.transactions
      .reduce((acc, t) => acc.concat(t.postings), [])
      .map((p) => p.account)
      .sort((a, b) => b.length - a.length)[0]
      .length

    this.accounts.forEach((account) => {
      lines.push(`account ${account.name}`)

      account.address.split('\n').forEach((address) => {
        lines.push(`  address ${address}`)
      })

      lines.push('')
    })

    this.transactions.forEach((transaction) => {
      const date = floatToDateString(transaction.date)
      const referenceId = transaction.referenceId ? ` (${transaction.referenceId})` : ''

      lines.push(`${date}  ${transaction.status}${referenceId} ${transaction.payee}`)

      transaction.postings.forEach((posting) => {
        const comment = posting.comment ? `  ; ${posting.comment}` : ''
        const account = pad(posting.account, largestAccountLength, ' ', true)
        const amount = pad(formatAmount(posting.amount, posting.currency).replace(',', ''), 15)

        lines.push(`  ${account}${posting.elided ? '' : amount}${comment}`.trimEnd())
      })

      lines.push('')
    })

    return lines.join('\n')
  }
}

export default PlainText
