import { createRouter, createWebHashHistory } from 'vue-router'
import About from '@/app/views/about/component.vue'
import Export from '@/app/views/export/component.vue'
import Feedback from '@/app/views/feedback/component.vue'
import Transactions from '@/app/views/transactions/component.vue'
import Transaction from '@/app/views/transaction/component.vue'
import Sources from '@/app/views/sources/component.vue'
import SourceEdit from '@/app/views/sources/edit.vue'
import Scenarios from '@/app/views/scenarios/component.vue'
import Settings from '@/app/views/settings/component.vue'

const routes = [
  {
    path: '/:identity_id',
    name: 'transactions',
    component: Transactions,
    children: [
      {
        path: '/:identity_id/views/:view_id',
        name: 'view',
        component: About
      },
      {
        path: '/:identity_id/transactions/:transaction_id',
        name: 'transaction',
        component: Transaction
      },
      {
        path: '/:identity_id/about',
        name: 'about',
        component: About
      },
      {
        path: '/:identity_id/feedback',
        name: 'feedback',
        component: Feedback
      },
      {
        path: '/:identity_id/sources',
        name: 'sources',
        component: Sources
      },
      {
        path: '/:identity_id/sources/:source_id/edit',
        name: 'sourceEdit',
        component: SourceEdit
      },
      {
        path: '/:identity_id/scenarios',
        name: 'scenarios',
        component: Scenarios
      },
      {
        path: '/:identity_id/settings',
        name: 'settings',
        component: Settings
      },
      {
        path: '/:identity_id/export',
        name: 'export',
        component: Export
      }
    ]
  }, {
    path: '/:catch(.*)',
    name: 'welcome',
    component: Transactions
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export {
  router,
  routes
}
