<template>
  <Panel
    title="Scenarios"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <ul
        class="mt-10 list-disc divide-y divide-white/5"
      >
        <li>Invest 5% of my paycheque into the S&P</li>
        <li>Purchase a property in 8 months</li>
        <li>Take out a 50k loan @ 8%</li>
        <li>Regular paycheque of 15k biweekly until Aug 1</li>
        <li>Pay 5k monthly to a friend starting sept 1</li>
        <li>Buy a 45k boat on dec 1</li>
        <li>A property I have increases in value 3% per year</li>
        <li>I raise the rents 2% per year</li>
      </ul>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },
  props: ['app']
}
</script>
