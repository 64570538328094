const fetcher = (url) => window.fetch(url)
// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

class Url {
  constructor (options) {
    this.url = options.url
    this.fetcher = options.fetcher || fetcher
    this.modes = ['read']
  }

  get name () {
    try {
      const domain = (new URL(this.url)).hostname

      return `Url (${domain})`
    } catch (e) {
      return null
    }
  }

  async fetch () {
    const result = await this.fetcher(this.url)
    const body = await result.text()

    return body
  }
}
export default Url
