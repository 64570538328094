<template>
  <a
    href="javascript:;"
    class="fixed bottom-0 left-0 right-0 z-50 block text-white text-center bg-indigo-600 px-6 py-2.5"
    @click="reset"
  >
    Click here to reload new data.
  </a>
</template>

<script>
export default {
  props: ['app', 'reloads', 'resetReloads'],

  methods: {
    reset () {
      for (var i = 0; i < this.reloads.length; i++) {
        this.reloads[i]()
      }

      this.resetReloads()
    }
  }
}
</script>
