<template>
  <div class="progress-wrapper bg-neutral-400 dark:bg-neutral-600">
    <span class="value text-white dark:text-white">{{ title || queries.formatPercent(percentageUsed) }}</span>
    <div
      class="progress"
      :value="amount"
      :max="max"
      :style="`width: ${percentageUsed}%; background: ${percentColor}`"
    />
  </div>
</template>

<script>
import Queries from '@/queries/index.js'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    amount: {
      type: Number,
      default: 0
    },

    max: {
      type: Number,
      default: 0
    },

    queries: {
      type: Object,
      default () { return new Queries() }
    },

    alwaysGreen: {
      type: Boolean,
      default: false
    },

    alwaysRed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    percentColor () {
      if (this.alwaysGreen) {
        return '#5ac45d'
      } else if (this.alwaysRed || this.percentageUsed > 100) {
        return '#d52923'
      } else if (this.percentageUsed > 75) {
        return '#ddb111'
      } else {
        return '#5ac45d'
      }
    },

    percentageUsed () {
      return Math.max(this.amount / this.max * 100.00, 0)
    }
  }
}
</script>

<style lang="scss">
  .progress-wrapper {
    width: 12rem;
    overflow-x: hidden;
    vertical-align: middle;
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
    position: relative;

    @media (prefers-color-scheme: dark) {
      background: rgba(255, 255, 255, 0.15);
    }

    @media (max-width: 640px) {
      max-width: 8rem;
    }

    .value {
      font-size: 90%;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      margin-top: 0.1rem;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px;

      @media (prefers-color-scheme: dark) {
        text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 3px;
      }
    }

    .progress {
      border-top: 2px solid rgba(255, 255, 255, 0.15);
      border-left: 2px solid rgba(255, 255, 255, 0.15);
      border-radius: 4px;
      height: 1rem;
    }
  }
</style>
