import AWS from 'aws-sdk'

const STRIP_BEGINNING_AND_END_SLASHES = /^\/|\/$/g

class S3 {
  constructor (options) {
    this.options = options
    this.modes = ['read']
    this.s3 = new AWS.S3({
      endpoint: new AWS.Endpoint(options.endpoint || S3.DEFAULTS.endpoint),
      accessKeyId: options.accessKeyId,
      secretAccessKey: options.secretAccessKey,
      region: options.region || S3.DEFAULTS.region,
      apiVersion: 'latest',
      maxRetries: 1
    })
  }

  get name () {
    return `S3 (${this.options.bucket}/${this.key})`
  }

  get key () {
    return (this.options.filename || S3.DEFAULTS.key).replace(STRIP_BEGINNING_AND_END_SLASHES, '')
  }

  fetch () {
    return new Promise((resolve, reject) => {
      this.s3.getObject({
        Bucket: this.options.bucket,
        Key: this.key,
        ResponseCacheControl: 'no-cache'
      }, (err, content) => {
        if (!content || !content.Body) {
          return reject(new Error('No data returned'))
        }

        resolve(content.Body.toString())
      })
    })
  }
}

S3.DEFAULTS = {
  endpoint: 's3.us-east-1.amazonaws.com',
  region: 'us-east-1',
  secretAccessKey: '',
  accessKeyId: '',
  bucket: '',
  key: 'accounting.dat'
}

export default S3
