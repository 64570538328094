<template>
  <Panel :back="{ name: 'transactions', params: { identity_id: app.identity.id } }">
    <template #content>
      <div class="mx-auto">
        <p class="text-indigo-600">
          <em>
            Personal finance apps are confusing, shallow with insights, and vague about the future.
            Usually, they sell your data or upsell you to the latest credit card promos...
          </em>
        </p>
        <h1 class="mt-10 text-3xl text-white">
          Why FastFinance?
        </h1>
        <p class="mt-10">
          FastFinance is an insight-rich, future-planning personal finance platform &mdash; with privacy in mind from the get-go (eg. nothing about you ever leaves your device).
        </p>
        <p class="mt-10">
          Here are a few of the driving forces for building the app:
        </p>
        <ul
          class="mt-8 space-y-2"
        >
          <li>
            <span>👓 I want to know my bottom line across all my pursuits.</span>
          </li>
          <li>
            <span>📌 I want a single place to store all of my financial data.</span>
          </li>
          <li>
            <span>🏦 I want a safe place to store my financial data forever.</span>
          </li>
          <li>
            <span>📈 I want to make data-based decisions about my future.</span>
          </li>
        </ul>
        <p class="mt-8">
          With these core tenets in mind, we've come to FastFinance v3.0.
          Re-built it to be faster, flexible, more insightful, and more powerful.
        </p>
        <div class="mt-8 flex items-start">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0,0,300,150"
            >
              <g
                fill="currentColor"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              ><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,5.623 3.872,10.328 9.092,11.63c-0.056,-0.162 -0.092,-0.35 -0.092,-0.583v-2.051c-0.487,0 -1.303,0 -1.508,0c-0.821,0 -1.551,-0.353 -1.905,-1.009c-0.393,-0.729 -0.461,-1.844 -1.435,-2.526c-0.289,-0.227 -0.069,-0.486 0.264,-0.451c0.615,0.174 1.125,0.596 1.605,1.222c0.478,0.627 0.703,0.769 1.596,0.769c0.433,0 1.081,-0.025 1.691,-0.121c0.328,-0.833 0.895,-1.6 1.588,-1.962c-3.996,-0.411 -5.903,-2.399 -5.903,-5.098c0,-1.162 0.495,-2.286 1.336,-3.233c-0.276,-0.94 -0.623,-2.857 0.106,-3.587c1.798,0 2.885,1.166 3.146,1.481c0.896,-0.307 1.88,-0.481 2.914,-0.481c1.036,0 2.024,0.174 2.922,0.483c0.258,-0.313 1.346,-1.483 3.148,-1.483c0.732,0.731 0.381,2.656 0.102,3.594c0.836,0.945 1.328,2.066 1.328,3.226c0,2.697 -1.904,4.684 -5.894,5.097c1.098,0.573 1.899,2.183 1.899,3.396v2.734c0,0.104 -0.023,0.179 -0.035,0.268c4.676,-1.639 8.035,-6.079 8.035,-11.315c0,-6.627 -5.373,-12 -12,-12z" /></g></g>
            </svg>
          </div>
          <p class="ml-3">
            If you're looking for the source code for FastFinance, you can find it at <a
              href="https://github.com/dallasread/my.fastfinance.app"
              target="_blank"
              class="text-white underline"
            >github.com/dallasread/my.fastfinance.app</a>. I encourage you to host your own instance, submit pull requests and report issues!
          </p>
        </div>
        <p class="mt-8">
          Happy counting!
          <!-- Lately, I've been perusing my bank accounts to reconstruct my financial history over the last 10 years.
              I've found a certain appeal in the nostalgia of looking back at my journey over the years: first car, first house, first kid, etc. Having the ability to easily recall this data at any time is incredibly powerful and brings back a flood of memories.
              Paying any cloud service to handle my financial information feels like a disaster waiting to happen.
              It's not a matter of if they'll have a security breach, but when…
              At the least, FastFinance provides security through obscurity.
              You own your data and store it where you want; I own my data and store it where I want.
              This is a massive step up against the database-hackers and security-breachers of the world.
              At the most, FastFinance arms you with full end-to-end-encryption – just remember where you put your keys!
              I have a few financial endeavours on-the-go: investment properties, consulting, web stores, running a family, and more.
              These can be hard to juggle in real-time.
              More importantly, its hard to juggle the future of these concerns. -->
        </p>
      </div>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },
  props: ['app']
}
</script>
