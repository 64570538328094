<template>
  <Panel
    title="Settings"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <form
        class="grid grid-cols-1 gap-y-2"
        @submit.prevent="save"
      >
        <p class="my-5">
          An identity is a way to group all the information around a single tax entity (eg. person or corporation).
          An identity can retrieve data from multiple data sources.
          Data sources can be used by multiple identities.
        </p>

        <div class="col-span-full">
          <label class="block text-neutral-400">Name</label>
          <input
            v-model="newName"
            type="text"
            class="block w-full rounded-md border-0 bg-neutral-800/5 dark:bg-white/5 py-1.5 mt-1 text-white text-sm"
          >
        </div>
        <div class="col-span-full mt-5">
          <button
            type="submit"
            class="block w-full rounded-md bg-indigo-500 px-3 py-2 text-sm text-white"
          >
            Save settings
          </button>
        </div>
      </form>

      <a
        href="javascript:;"
        class="block w-full text-center text-xs text-red-600 hover:text-red-400 mt-7"
        @click="deleteIdentity"
      >delete this identity</a>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },
  props: ['app'],
  data () {
    return {
      newName: this.app.identity.name
    }
  },
  methods: {
    save () {
      this.app.commands.updateIdentityName(this.app.identity, this.newName)
      this.$router.go(-1)
    },

    deleteIdentity () {
      if (confirm('All transactions, scenarios, and sources for this identity will be erased from this device. Are you sure you want to remove this identity?')) {
        this.app.commands.removeIdentity(this.app.identity)
        window.location.reload()
      }
    }
  }
}
</script>
