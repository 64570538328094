import localForage from 'localforage'

class LocalAdapter {
  constructor (name) {
    this.db = localForage.createInstance({ name })
  }

  restore () {
    return new Promise((resolve, reject) => {
      this.db.keys().then((keys) => {
        Promise.all(
          keys.map((id) => this.get(id))
        )
          .then(resolve)
          .catch(reject)
      }).catch(reject)
    })
  }

  get (id) {
    return new Promise((resolve, reject) => {
      this.db.getItem(id).then(resolve).catch(reject)
    })
  }

  set (id, data) {
    return this.db.setItem(id, data)
  }

  remove (id) {
    return this.db.removeItem(id)
  }

  reset () {
    return this.db.clear()
  }
}

export default LocalAdapter
