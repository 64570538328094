class Source {
  constructor (attrs) {
    this.id = attrs.id
    this.identityId = attrs.identityId
    this.type = attrs.type
    this.processor = attrs.processor
    this.config = attrs.config || {}
    this.raw = attrs.raw
    this.updatedAt = attrs.updatedAt
    this.isLoading = false
  }
}

export default Source
