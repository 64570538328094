<template>
  <div>
    <ReloadBanner
      v-if="reloads.length"
      :reloads="reloads"
      :reset-reloads="resetReloads"
    />
    <ErrorDialog
      v-if="exception"
      :error="exception"
      :clear-error="clearException"
    />
    <router-view
      v-if="app.identity"
      :app="app"
    />
  </div>
</template>

<script>
import saveAs from 'file-saver'
import Commands from '@/commands/index.js'
import State from '@/state/index.js'
import Queries from '@/queries/index.js'
import LocalAdapter from '@/adapters/local.js'
import ErrorDialog from '@/app/components/error-dialog/component.vue'
import ReloadBanner from '@/app/components/reload-banner/component.vue'
import Account from '@/structs/account.js'
import Identity from '@/structs/identity.js'
import Periodic from '@/structs/periodic.js'
import Sheet from '@/structs/sheet.js'
import Source from '@/structs/source.js'
import Transaction from '@/structs/transaction.js'

export default {
  components: {
    ErrorDialog,
    ReloadBanner
  },

  props: {
    state: {
      type: Object,
      default () {
        return new State({
          identities: Identity,
          sheets: Sheet,
          sources: Source,
          transactions: Transaction,
          accounts: Account,
          periodics: Periodic
        })
      }
    },
    identitiesAdapter: {
      type: Object,
      default: () => new LocalAdapter('fast-finance-v3')
    },
    saveAs: {
      type: Function,
      default: saveAs
    },
    blob: {
      type: Function,
      default: function () {
        return new Blob(...arguments)
      }
    }
  },

  data () {
    window.fastFinance = this

    const queries = new Queries({
      state: this.state,
      identitiesAdapter: this.identitiesAdapter
    })

    const commands = new Commands({
      state: queries.state,
      queries,
      identitiesAdapter: this.identitiesAdapter,
      saveAs: this.saveAs,
      blob: this.blob
    })

    return {
      app: this,
      exception: null,
      reloads: [],
      queries,
      commands,
      zoomer: {
        title: null,
        transactions: []
      }
    }
  },

  computed: {
    identity () {
      return this.queries.findIdentityById(this.$route.params.identity_id)
    },

    hasIdentities () {
      return this.queries.findAllIdentities().length > 0
    }
  },

  async mounted () {
    try {
      await this.commands.restoreIdentitiesFromLocal()
      await this.ensureIdentity()
    } catch (e) {
      this.showException(e)
    }
  },

  methods: {
    showException (exception) {
      this.exception = exception
    },

    clearException () {
      this.exception = null
    },

    sourceLoadingClass (isLoading) {
      if (isLoading) {
        return 'transition-all animate-[pulse_750ms_ease-in-out_infinite] text-yellow-400 bg-yellow-400/10 hover:bg-yellow-400/30'
      } else {
        return 'transition-all text-green-400 bg-green-400/10 hover:bg-green-400/30'
      }
    },

    resetReloads () {
      this.reloads = []
    },

    addReload (func) {
      this.reloads.push(func)
    },

    async refreshIdentitySources (identity, withNotifyBanner = true) {
      const sourcesData = await this.app.commands.refreshIdentitySources(identity)
      const transactions = sourcesData.reduce((acc, data) => acc.concat(data.transactions), [])
      const accounts = sourcesData.reduce((acc, data) => acc.concat(data.accounts), [])
      const periodic = sourcesData.reduce((acc, data) => acc.concat(data.periodics), [])

      const update = () => {
        this.app.commands.resetAccountsForIdentity(identity)
        this.app.commands.addAccountsForIdentity(identity, accounts)
        this.app.commands.resetTransactionsForIdentity(identity)
        this.app.commands.addTransactionsForIdentity(identity, transactions)
        this.app.commands.addPeriodicTransactionsForIdentity(identity, periodic)
      }

      if (withNotifyBanner) {
        return this.addReload(() => update())
      }

      update()
    },

    async ensureIdentity () {
      try {
        await this.commands.addSeedIdentityIfRequired()
      } catch (e) {
        return this.showException(e)
      }

      if (!this.identity) {
        const identity = this.queries.findAllIdentities()[0]

        return this.$router.push({ name: 'transactions', params: { identity_id: identity.id } })
      }
    },

    zoom (title, transactions) {
      this.zoomer.title = title
      this.zoomer.transactions = transactions
    }
  }
}
</script>
