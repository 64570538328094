<template>
  <Dropdown
    x-align="right"
    width-class="w-100 lg:w-80"
    prompt-class="block"
  >
    <template
      #prompt
      class="block"
    >
      <slot />
    </template>
    <template #content="props">
      <div
        class="text-sm p-3 w-100 lg:w-50"
        @click="props.close"
      >
        <label class="block mb-2 text-neutral-800 dark:text-neutral-400">Timeline</label>

        <div class="block isolate flex rounded-md shadow-sm">
          <button
            type="button"
            :class="`grow rounded-l-md ${selectedClass('month')} px-3 py-2 ring-1 ring-inset ring-neutral-300 dark:ring-neutral-700`"
            @click="updateSheetDatePeriod(sheet, 'month')"
          >
            Month
          </button>
          <button
            type="button"
            :class="`grow ${selectedClass('year')} px-3 py-2 ring-1 ring-inset ring-neutral-300 dark:ring-neutral-700`"
            @click="updateSheetDatePeriod(sheet, 'year')"
          >
            Year
          </button>
          <button
            type="button"
            :class="`grow rounded-r-md ${selectedClass('all')} px-3 py-2 ring-1 ring-inset ring-neutral-300 dark:ring-neutral-700`"
            @click="updateSheetDatePeriod(sheet, 'all')"
          >
            All time
          </button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@/app/components/dropdown/component.vue'

export default {
  components: {
    Dropdown
  },

  props: ['sheet', 'updateSheetDatePeriod'],

  data () {
    return {
      newDateType: ''
    }
  },

  computed: {
    dateFilter () {
      return this.sheet.dateFilter
    }
  },

  watch: {
    sheet () {
      this.updateNewDateType(this.sheet)
    }
  },

  mounted () {
    this.updateNewDateType(this.sheet)
  },

  methods: {
    selectedClass (type) {
      return this.newDateType === type ? 'bg-white dark:bg-neutral-900 text-neutral-900 dark:text-white' : 'bg-neutral-100 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400'
    },

    updateNewDateType (sheet) {
      this.newDateType = sheet.dateFilter.period
    }
  }
}
</script>
