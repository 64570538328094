<template>
  <div>
    <div
      v-if="!transactions.length"
      class="text-white text-center whitespace-nowrap p-8"
    >
      <p class="grid h-32 place-items-center">
        No transactions found.
      </p>
    </div>
    <div
      v-else
    >
      <p class="p-4 text-neutral-400">
        Use something like `account:"Assets"` to see your assets listed.
      </p>
      <div class="p-4">
        <div
          v-for="account in accounts"
          :key="`account-${account}`"
          class="text-neutral-400 mb-6"
        >
          <AssetsChart
            :app="app"
            :transactions="transactions"
            :account="account"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetsChart from '@/app/views/transactions/charts/assets-chart.vue'

export default {
  components: {
    AssetsChart
  },
  props: ['app', 'sheet', 'transactions'],
  computed: {
    postings () {
      return this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
    },

    accounts () {
      return [...new Set(this.postings.map((p) => p.account))].sort()
    }
  }
}
</script>
