<template>
  <div>
    <h3 class="text-white font-semibold">
      {{ simpleName(account) }}
    </h3>
    <p class="text-s">
      <em>Acquired: {{ app.queries.prettyDate(startAt) }}</em>
    </p>

    <!-- [Chart] -->

    <table class="mt-2 w-full divide-y divide-neutral-800 border border-neutral-800">
      <thead>
        <tr>
          <th class="py-2 px-3 text-left text-neutral-400 sm:pl-6 lg:pl-8">
            Year
          </th>
          <th class="py-2 px-3 text-right text-neutral-400">
            Income
          </th>
          <th class="py-2 px-3 text-right text-neutral-400">
            Expenses
          </th>
          <th class="py-2 px-3 text-right text-neutral-400">
            Net
          </th>
          <th
            class="py-2 px-3 text-right text-neutral-400"
            title="Net vs. all expenses"
          >
            ROI
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-neutral-800 text-white">
        <tr
          v-for="item in items"
          :key="`data-${item.date}`"
          class="text-neutral-400 hover:bg-neutral-950"
        >
          <td class="py-2 px-3 pl-8 text-right">
            {{ item.date.getFullYear() }}
          </td>
          <td class="py-2 px-3 pl-8 text-right">
            {{ formatAmount(item.Income, currency) }}
          </td>
          <td class="py-2 px-3 text-right">
            {{ formatAmount(item.Expenses, currency) }}
          </td>
          <td class="py-2 px-3 text-right">
            {{ formatAmount(item.Net, currency) }}
          </td>
          <td class="py-2 px-3 text-right">
            {{ app.queries.formatPercent(item.Growth) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatAmount, floatToDate, getStartOfYear, sumPostingsForCurrency, getEndOfYear } from '@/parse-and-play/utils'

const SORT_BY_DATE = (a, b) => new Date(a.date) - new Date(b.date)
const FILTER_BETWEEN_DATES = (startAt, endAt) => {
  return (transaction) => {
    const date = floatToDate(transaction.date)

    if (date.getTime() < startAt.getTime()) return false
    if (date.getTime() > endAt.getTime()) return false

    return true
  }
}

export default {
  props: ['app', 'sheet', 'transactions', 'account'],

  computed: {
    sortedTransactions () {
      return this.transactions
        .filter((t) => t.getPostings().filter((p) => p.account === this.account).length)
        .sort(SORT_BY_DATE)
    },

    startAt (account) {
      return this.sortedTransactions[0].date
    },

    endAt () {
      return this.sortedTransactions[this.sortedTransactions.length - 1].date
    },

    currency () {
      return 'CAD'
    },

    items () {
      const items = []
      const simpleAccountName = this.simpleName(this.account)
      const startAt = floatToDate(this.startAt)
      let endAt = floatToDate(this.endAt)
      const date = getStartOfYear(startAt)
      const totalExpenses = Math.abs(sumPostingsForCurrency(this.transactions.reduce((acc, t) => acc.concat(t._original.postings), []).filter((p) => p.account.indexOf('Expenses:') === 0 && p.account.indexOf(simpleAccountName) !== -1), this.currency))

      if (endAt < getEndOfYear(new Date())) {
        endAt = getEndOfYear(new Date())
      }

      // I also want to see expenses over recurring ratio

      while (date.getTime() < endAt.getTime()) {
        const newDate = new Date(date)
        const postings = this.postingsBetween(newDate, getEndOfYear(newDate)).filter((p) => p.account.indexOf(simpleAccountName) !== -1)
        const incomePostings = postings.filter((p) => p.account.indexOf('Income:') === 0)
        const expensesPostings = postings.filter((p) => p.account.indexOf('Expenses:') === 0)
        const Income = Math.abs(sumPostingsForCurrency(incomePostings, this.currency))
        const Expenses = Math.abs(sumPostingsForCurrency(expensesPostings, this.currency))
        const Net = Income - Expenses

        const item = {
          date: newDate,
          Income,
          Expenses,
          Net,
          Growth: totalExpenses <= 0 ? 0 : (Net / totalExpenses) * 100.0
        }

        items.push(item)

        date.setFullYear(date.getFullYear() + 1)
      }

      return items
    }
  },

  methods: {
    simpleName (account) {
      return account.split(':').pop()
    },

    postingsBetween (startAt, endAt) {
      return this.transactions
        .filter(FILTER_BETWEEN_DATES(startAt, endAt))
        .reduce((acc, t) => acc.concat(t._original.postings), [])
    },

    formatAmount
  }
}
</script>
