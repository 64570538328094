<template>
  <Panel
    title="Data Sources"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <p class="my-5">
        Data sources provide the data (eg. transactions, categories) for an identity.
        A data source can come in many different forms, such as: a bank, a brokerage account, a plain text accounting file on an S3 bucket, a file upload.
      </p>

      <ul
        role="list"
        class="divide-y divide-white/5 mb-5"
      >
        <li
          v-for="source in sources"
          :key="`list-source-${source.id}`"
        >
          <router-link
            :to="{ name: 'sourceEdit', params: { identity_id: app.identity.id, source_id: source.id } }"
            class="relative flex items-center rounded-md space-x-4 p-4 bg-neutral-100 dark:bg-neutral-700"
          >
            <div class="min-w-0 flex-auto">
              <div class="flex items-center gap-x-3">
                <div :class="`flex-none rounded-full p-1 ${app.sourceLoadingClass(source.isLoading)}`">
                  <div class="h-2.5 w-2.5 rounded-full bg-current" />
                </div>
                <h2 class="min-w-0 text-neutral-700 dark:text-white">
                  <a
                    href="javascript:;"
                    class="flex gap-x-2"
                  >
                    <span class="truncate mr-3">{{ app.queries.getSourceName(source) }}</span>
                    <span
                      v-for="mode in app.queries.getSourceModes(source)"
                      :key="`list-source-mode-${source.id}-${mode}`"
                      class="rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset text-white bg-yellow-400 ring-yellow-400/30 dark:bg-yellow-400/10 dark:text-yellow-400 dark:ring-yellow-400/20"
                    >
                      {{ mode }}
                    </span>
                    <span class="absolute inset-0" />
                  </a>
                </h2>
              </div>
              <div class="mt-1 flex items-center gap-x-2.5 text-sm">
                <p
                  v-if="source.updatedAt"
                  class="whitespace-nowrap"
                >
                  Last configured {{ app.queries.prettyTime(source.updatedAt) }}
                </p>
              </div>
            </div>
            <svg
              class="h-5 w-5 flex-none text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd"
              />
            </svg>
          </router-link>
        </li>
      </ul>

      <button
        type="submit"
        class="block w-full rounded-md bg-indigo-500 px-3 py-2 text-sm text-white"
        @click="addSource"
      >
        + Add Source
      </button>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },

  props: ['app'],

  computed: {
    sources () {
      return this.app.queries.findAllSources(this.app.identity.id)
    }
  },

  methods: {
    addSource () {
      this.app.commands.addSourceForIdentity(this.app.identity, { type: 'Url', processor: 'PlainText' })
      const sources = this.app.queries.findAllSources(this.app.identity.id)
      this.$router.push({ name: 'sourceEdit', params: { identity_id: this.app.identity.id, source_id: sources[sources.length - 1].id } })
    }
  }
}
</script>
