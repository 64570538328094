class Budget {
  constructor (attrs) {
    this.id = attrs.id
    this.account = attrs.account
    this.currency = attrs.currency
    this.amount = attrs.amount
    this.totalUsed = 0
    this.elided = attrs.elided
    this.transactions = []
  }

  applyTransactions (transactions) {
    this.transactions = transactions
      .filter((transaction) => {
        const summary = transaction.sumAccounts()

        if (!summary[this.account]) return false
        if (!summary[this.account][this.currency]) return false

        return true
      })

    this.transactions.forEach((transaction) => {
      const summary = transaction.sumAccounts()
      this.setTotalUsed(this.totalUsed + summary[this.account][this.currency] || 0)
    })
  }

  setTotalUsed (totalUsed) {
    this.totalUsed = totalUsed
  }

  setAmount (amount) {
    this.amount = amount
  }
}

Budget.fromPosting = (posting) => {
  return new Budget({
    account: posting.account,
    currency: posting.currency,
    amount: posting.amount,
    elided: posting.elided
  })
}

export default Budget
