import { matchesAccount } from '@/parse-and-play/utils.js'

const groupBy = (items, key) => {
  return items.reduce((acc, item) => {
    (acc[item[key]] = acc[item[key]] || []).push(item)
    return acc
  }, {})
}

class Transaction {
  constructor (attrs) {
    this.id = attrs.id
    this.comments = attrs.comments
    this.postings = attrs.postings
    this.date = attrs.date
    this.referenceId = attrs.referenceId
    this.payee = attrs.payee
    this.status = attrs.status
    this.identityId = attrs.identityId
    this.periodicName = attrs.periodicName
  }

  get cleared () {
    return this.status === Transaction.STATUSES.CLEARED
  }

  sum (account) {
    const summary = {}

    this.postings.forEach((posting) => {
      if (!matchesAccount(posting.account, account)) return

      if (typeof summary[posting.account] === 'undefined') {
        summary[posting.account] = {}
      }

      if (typeof summary[posting.account][posting.currency] === 'undefined') {
        summary[posting.account][posting.currency] = 0
      }

      if (typeof posting.amount !== 'undefined') {
        summary[posting.account][posting.currency] += posting.amount
      }
    })

    return summary
  }

  getPostings (filters = []) {
    const postings = this.postings

    if (filters.length === 0) {
      return postings
    }

    const groups = groupBy(filters, (f) => f.type)

    return postings.filter((posting) => {
      for (const type in groups) {
        const typeFilters = groups[type]

        if (typeFilters.filter((f) => f.filter()(posting)).length) {
          continue
        }

        return false
      }

      return true
    })
  }
}

Transaction.STATUSES = {
  PENDING: '!',
  CLEARED: '*'
}

export default Transaction
