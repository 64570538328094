<template>
  <Panel :back="{ name: 'transactions', params: { identity_id: app.identity.id } }">
    <template #content>
      <div class="mx-auto">
        <h1 class="mt-10 text-3xl text-white">
          Bugs & Feature Requests
        </h1>
        <p class="mt-10">
          Have any questions? Bugs? Feature requests?<br><br>
          Reach out at dallas@excitecreative.ca.
        </p>
      </div>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },
  props: ['app']
}
</script>
