<template>
  <div :class="`${isInline ? '' : 'lg:'}inline-block relative`">
    <div
      v-if="isOpen"
      class="fixed top-0 right-0 bottom-0 left-0 z-40"
      @click="close"
    />
    <div
      ref="content"
      :class="`relative ${isInline ? '' : 'lg:'}inline-block text-left`"
    >
      <a
        href="javascript:;"
        :class="`${isInline ? '' : 'lg:'}inline-block text-neutral-500 dark:text-neutral-600 text-center`"
        @click="toggle"
      >
        <slot
          name="prompt"
          :isOpen="isOpen"
        />
      </a>
      <div
        v-if="isOpen"
        :class="`absolute ${xAlign}-0 z-50 ${isInline ? '' : 'lg:'}${widthClass} origin-top-${xAlign} border border-neutral-300 dark:border-neutral-800 rounded-md dark:shadow-sm bg-neutral-100 dark:bg-neutral-900`"
      >
        <slot
          name="content"
          :close="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    xAlign: {
      type: String,
      default: 'left'
    },
    widthClass: {
      type: String,
      default: 'w-60'
    },
    isInline: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isOpen: false
    }
  },

  watch: {
    isOpen (value) {
      this.$nextTick(() => {
        const input = this.$refs.content.querySelector('input, select')

        if (value && input) {
          input.focus()
        }
      })
    }
  },

  methods: {
    open () {
      this.isOpen = true
    },

    close () {
      this.isOpen = false
    },

    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
