<template>
  <div class="absolute top-0 right-0 bottom-0 left-0 z-50 transform transition">
    <div class="flex h-screen">
      <div
        class="print:hidden lg:basis-1/3 bg-neutral-500 dark:bg-neutral-950 opacity-75 shadow-lg"
        @click="goBack"
      />
      <div class="lg:basis-2/3 w-full print:basis-full p-4 lg:p-16 bg-white dark:bg-neutral-900 text-neutral-500 dark:text-neutral-400 print:border-none dark:border-l border-neutral-800 overflow-y-scroll">
        <a
          href="javascript:;"
          class="print:hidden fixed top-0 right-0 mr-3 mt-1 text-3xl text-neutral-400 dark:text-neutral-700"
          @click="goBack"
        >
          &times;
        </a>
        <h2
          v-if="title"
          class="print:hidden text-neutral-700 dark:text-white font-semibold text-lg"
        >
          {{ title }}
        </h2>
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'back'],

  mounted () {
    document.body.style.overflow = 'hidden'

    const $input = this.$el.querySelector('input, textarea')

    if ($input) {
      $input.focus()
    }
  },

  beforeUnmount () {
    document.body.style.overflow = ''
  },

  methods: {
    goBack () {
      if (typeof this.back === 'function') {
        this.back()
      } else {
        this.$router.push(this.back)
      }
    }
  }
}
</script>
