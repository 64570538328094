<template>
  <table class="w-full divide-y divide-neutral-200 dark:divide-neutral-800">
    <thead>
      <tr>
        <th class="py-3.5 pl-4 pr-3 text-left">
          Account
        </th>
        <th class="px-3 py-3.5 text-left">
          Progress
        </th>
        <th class="px-3 py-3.5 text-left">
          Details
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-neutral-200 dark:divide-neutral-800">
      <tr
        v-for="budget in budgets"
        :key="budget"
      >
        <td class="whitespace-nowrap py-2 px-3 pl-8">
          <a
            href="javascript:;"
          >
            {{ budget.account }}
          </a>
        </td>
        <td class="whitespace-nowrap py-2 px-3">
          <ProgressBar
            :amount="budget.totalUsed"
            :max="budget.amount"
            :always-green="budget.elided ? budget.amount >= 0 : budget.amount < 0"
          />
        </td>
        <td class="whitespace-nowrap py-2 px-3 pr-8">
          <span :title="queries.formatAmount(budget.totalUsed - budget.amount, budget.currency)">
            {{ queries.formatAmount(budget.totalUsed, budget.currency) }} / {{ queries.formatAmount(budget.amount, budget.currency) }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Queries from '@/queries/index.js'
import ProgressBar from '@/app/components/progress-bar/component.vue'

export default {
  components: {
    ProgressBar
  },

  props: {
    transactions: {
      type: Array,
      default () { return [] }
    },

    budgets: {
      type: Array,
      default () { return [] }
    },

    queries: {
      type: Object,
      default () { return new Queries() }
    }
  },

  computed: {
    postings () {
      return this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
    },

    accounts () {
      const accs = [...new Set(this.postings.map((p) => p.account))].sort()
      const explodedAccounts = []

      for (var i = 0; i < accs.length; i++) {
        const splat = accs[i].split(':')

        for (var n = 0; n < splat.length; n++) {
          const fullAccount = splat.slice(0, n + 1).join(':')

          if (explodedAccounts.indexOf(fullAccount) === -1) {
            explodedAccounts.push(fullAccount)
          }
        }
      }

      return explodedAccounts
    },

    currencies () {
      return [...new Set(this.postings.map((p) => p.currency))]
    }
  }
}
</script>
