<template>
  <span ref="sortable">
    <span
      v-for="item in items"
      :key="item[itemKey]"
      :data-id="item[itemKey]"
    >
      <slot
        name="item"
        :item="item"
      />
    </span>
  </span>
</template>

<script>
import Sortable from 'sortablejs'

export default {
  props: ['items', 'itemKey', 'onChange'],

  mounted () {
    const sortable = Sortable.create(this.$refs.sortable, {
      delay: /Mobi|Android/i.test(window.navigator.userAgent) ? 300 : 50,
      onSort: () => {
        this.onChange(sortable.toArray())
      }
    })
  }
}
</script>
