<template>
  <div class="bg-white dark:bg-neutral-900 dark text-sm z-40">
    <p
      v-if="dateFilter.period === 'year'"
      class="flex"
    >
      <a
        href="javascript:;"
        class="px-3 py-3 text-neutral-400 dark:text-neutral-600 border-x border-neutral-200 dark:border-neutral-800"
        @click="setYear(-1)"
      >&larr;</a>
      <DateTypeDropdown
        :sheet="sheet"
        :update-sheet-date-period="updateSheetDatePeriod"
        class="w-full"
      >
        <p class="py-3 px-8 min-w-16 text-center text-neutral-900 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-800">
          {{ year }}
        </p>
      </DateTypeDropdown>
      <a
        href="javascript:;"
        class="px-3 py-3 text-neutral-400 dark:text-neutral-600 border-x border-neutral-300 dark:border-neutral-800"
        @click="setYear(+1)"
      >&rarr;</a>
    </p>
    <p
      v-else-if="dateFilter.period === 'month'"
      class="flex"
    >
      <a
        href="javascript:;"
        class="px-3 py-3 text-neutral-400 dark:text-neutral-600 border-x border-neutral-300 dark:border-neutral-800"
        @click="setMonth(-1)"
      >&larr;</a>
      <DateTypeDropdown
        :sheet="sheet"
        :update-sheet-date-period="updateSheetDatePeriod"
        class="hover:bg-neutral-100 dark:hover:bg-neutral-800 w-full"
      >
        <p class="mx-8 py-3 min-w-32 text-center text-neutral-900 dark:text-neutral-200">
          {{ month }}
        </p>
      </DateTypeDropdown>
      <a
        href="javascript:;"
        class="px-3 py-3 text-neutral-400 dark:text-neutral-600 border-x border-neutral-300 dark:border-neutral-800"
        @click="setMonth(+1)"
      >&rarr;</a>
    </p>
    <DateTypeDropdown
      v-else
      :sheet="sheet"
      :update-sheet-date-period="updateSheetDatePeriod"
      class="hover:bg-neutral-100 dark:hover:bg-neutral-800 w-full"
    >
      <p class="px-8 py-3 border-x border-neutral-300 text-neutral-900 dark:text-neutral-200 dark:border-neutral-800">
        All time
      </p>
    </DateTypeDropdown>
  </div>
</template>

<script>
import DateTypeDropdown from '@/app/components/date-type-dropdown/component.vue'

export default {
  components: {
    DateTypeDropdown
  },
  props: ['sheet', 'updateSheetDatePeriod', 'updateSheetDateValue'],
  computed: {
    rawDate () {
      return new Date(this.dateFilter.value)
    },

    month () {
      return this.rawDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long' })
    },

    year () {
      return this.rawDate.toLocaleDateString('en-us', { year: 'numeric' })
    },

    dateFilter () {
      return this.sheet.dateFilter
    }
  },
  methods: {
    setMonth (delta) {
      const newDate = new Date(this.rawDate)
      newDate.setMonth(this.rawDate.getMonth() + delta)
      this.updateSheetDateValue(this.sheet, newDate)
    },

    setYear (delta) {
      const newDate = new Date(this.rawDate)
      newDate.setFullYear(this.rawDate.getFullYear() + delta)
      this.updateSheetDateValue(this.sheet, newDate)
    }
  }
}
</script>
