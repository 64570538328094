const fetcher = (url) => window.fetch(url)

class DunderMifflin {
  constructor (options) {
    this.url = '/demo-data/dunder-mifflin.txt'
    this.fetcher = options.fetcher || fetcher
    this.modes = ['read']
  }

  get name () {
    return 'Demo (Dunder Mifflin)'
  }

  async fetch () {
    const date = new Date()
    const result = await this.fetcher(this.url)
    const body = (await result.text())
      .replace(/\$YEAR/g, date.getFullYear())
      .replace(/\$PREVIOUS_MONTH/g, String(date.getMonth()).padStart(2, 0))
      .replace(/\$MONTH/g, String(date.getMonth() + 1).padStart(2, 0))

    return body
  }
}
export default DunderMifflin
