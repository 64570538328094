import { DateFilter, TypeFilter, AccountFilter, PayeeFilter, ClearedFilter, BudgetFilter, HasTxnIdFilter, Filter } from '@/structs/filter.js'

class Sheet {
  constructor (attrs) {
    this.id = attrs.id
    this.name = attrs.name
    this.ordinal = attrs.ordinal
    this.typeFilter = attrs.typeFilter
    this.dateFilter = attrs.dateFilter
    this.filters = attrs.filters
    this.identityId = attrs.identityId
  }

  get transactionFilters () {
    return this.filters
      .filter((f) => f.appliesTo === Filter.APPLIES_TO.TRANSACTION)
      .concat(this.dateFilter)
  }

  get postingFilters () {
    return this.filters
      .filter((f) => f.appliesTo === Filter.APPLIES_TO.POSTING)
  }

  filterString () {
    return `${[this.typeFilter, this.dateFilter].concat(this.filters).join(' ')}`
  }
}

Sheet.SHEETS = [
  new Sheet({
    name: 'Inbox',
    typeFilter: new TypeFilter('register'),
    dateFilter: DateFilter.all(),
    filters: [
      new ClearedFilter(false)
    ]
  }),

  new Sheet({
    name: 'Register',
    typeFilter: new TypeFilter('register'),
    dateFilter: DateFilter.thisMonth(),
    filters: []
  }),

  new Sheet({
    name: 'Cashflow',
    typeFilter: new TypeFilter('account'),
    dateFilter: DateFilter.thisMonth(),
    filters: [
      new AccountFilter('Income'),
      new AccountFilter('Expenses')
    ]
  }),

  new Sheet({
    name: 'King Cashflow',
    typeFilter: new TypeFilter('account'),
    dateFilter: DateFilter.thisYear(),
    filters: [
      // new ScenarioFilter('With 17 King'),
      new AccountFilter('Income'),
      new AccountFilter('Expenses')
    ]
  }),

  new Sheet({
    name: 'Budget',
    typeFilter: new TypeFilter('budget'),
    dateFilter: DateFilter.thisMonth(),
    filters: [
      new BudgetFilter(true)
    ]
  }),

  new Sheet({
    name: 'Net Worth',
    typeFilter: new TypeFilter('account'),
    dateFilter: DateFilter.all(),
    filters: [
      new AccountFilter('Assets'),
      new AccountFilter('Liabilities'),
      new ClearedFilter(true)
    ]
  }),

  new Sheet({
    name: 'Income',
    typeFilter: new TypeFilter('account'),
    dateFilter: DateFilter.all(),
    filters: [
      new AccountFilter('Assets:Real Estate'),
      new PayeeFilter('Vendor')
    ]
  }),

  new Sheet({
    name: 'Meals',
    typeFilter: new TypeFilter('account'),
    dateFilter: DateFilter.thisMonth(),
    filters: [
      new AccountFilter('Expenses:Meals')
    ]
  }),

  new Sheet({
    name: 'Invoices',
    typeFilter: new TypeFilter('register'),
    dateFilter: DateFilter.thisYear(),
    filters: [
      new HasTxnIdFilter(true),
      new AccountFilter('Income')
    ]
  })
]

export default Sheet
