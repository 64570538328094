import Processor from '@/parse-and-play/processor.js'

const rand = (min, max) => {
  return (Math.random() * (min - max) + max).toFixed(1)
}

class PlainTextRandomizer {
  async process (input) {
    const processor = Processor.simpleParse(input)
    const transactions = processor.transactions

    transactions.forEach((t) => {
      t.postings.forEach((p) => {
        p.amount = p.amount * rand(0.1, 0.3)
      })
    })

    return {
      accounts: Object.values(processor.accounts).map((account) => ({
        name: account.key,
        address: account.address
      })),
      transactions,
      periodics: processor.recurrences.map((recurrence) => {
        return {
          name: recurrence.name,
          frequency: recurrence.frequencyDescription.toLowerCase(),
          startAt: recurrence.startAt,
          endAt: recurrence.endAt,
          referenceId: recurrence.transaction.referenceId,
          comments: recurrence.transaction.comments || [],
          postings: recurrence.transaction.postings.map((posting) => {
            return {
              account: posting.account,
              amount: posting.amount,
              comment: posting.comment,
              currency: posting.currency,
              elided: posting.elided
            }
          })
        }
      })
    }
  }
}

export default PlainTextRandomizer
