class Account {
  constructor (key, address) {
    this.key = key
    this.address = address || ''

    Object.defineProperty(this, 'latestAttr', {
      writable: true,
      enumerable: false
    })
  }

  update (line) {
    if (/^address\s/.test(line)) {
      this.latestAttr = 'address'
      line = line.replace(/^address\s/, '')
    }

    if (this.latestAttr) {
      this[this.latestAttr] += `${this[this.latestAttr].length ? '\n' : ''}${line}`
    }
  }
}

Account.from = (line) => {
  const key = line.replace(/account\s+?/, '').trim()

  return new Account(key)
}

export default Account
