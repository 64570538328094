<template>
  <Dropdown
    v-if="isSelected"
    :is-inline="true"
  >
    <template #prompt="props">
      <span :class="sheetClass(sheet)">
        <span>
          {{ sheet.name }}
        </span>
        <span
          v-if="props.isOpen"
          class="ml-3 text-neutral-800 dark:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-3 h-3"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
          </svg>

        </span>
        <span
          v-else
          class="ml-3 text-neutral-500 dark:text-neutral-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-3 h-3"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </span>
    </template>
    <template #content="props">
      <form
        class="p-3 grid grid-cols-1 gap-y-2 text-sm"
        @submit.prevent="saveChanges(props.close)"
      >
        <div class="col-span-full">
          <label class="block text-neutral-400">Sheet Name</label>
          <input
            v-model="newName"
            type="text"
            class="block w-full rounded-md border-0 bg-white dark:bg-white/5 py-1.5 mt-1 text-neutral-800 dark:text-white text-sm"
          >
        </div>
        <div class="col-span-full">
          <label class="block text-neutral-400">Type</label>
          <select
            v-model="newType"
            class="block w-full rounded-md border-0 bg-white dark:bg-white/5 py-1.5 mt-1 text-neutral-800 dark:text-white text-sm"
          >
            <option value="account">
              Account
            </option>
            <option value="budget">
              Budget
            </option>
            <option value="register">
              Register
            </option>
            <option value="sankey">
              Sankey
            </option>
            <option value="assets">
              Assets
            </option>
          </select>
        </div>
        <div class="col-span-full">
          <button
            type="submit"
            class="block w-full rounded-md bg-indigo-500 px-3 py-2 text-sm text-white"
          >
            Save sheet
          </button>
        </div>
        <a
          href="javascript:;"
          class="block w-full text-center text-xs text-red-600 hover:text-red-400"
          @click="removeSheet(sheet)"
        >delete this sheet</a>
      </form>
    </template>
  </Dropdown>
  <a
    v-else
    href="javascript:;"
    :class="sheetClass(sheet)"
    @click="selectSheet(sheet)"
  >
    {{ sheet.name }}
  </a>
</template>

<script>
import Dropdown from '@/app/components/dropdown/component.vue'

export default {
  components: {
    Dropdown
  },

  props: ['sheet', 'selectSheet', 'removeSheet', 'updateSheetName', 'updateSheetType', 'isSelected'],

  data () {
    return {
      newName: '',
      newType: 'register'
    }
  },

  watch: {
    sheet (value) {
      this.parseSheet(value)
    }
  },

  mounted () {
    this.parseSheet(this.sheet)
  },

  methods: {
    sheetClass (sheet) {
      if (this.isSelected) {
        return 'text-neutral-800 text-sm dark:text-white mr-1 bg-white dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-800 border-b-0 inline-flex items-center rounded-t-lg py-2 px-3'
      }

      return 'text-neutral-400 text-sm dark:text-neutral-400 mr-1 border border-transparent border-b-0 hover:bg-white dark:hover:bg-neutral-900 hover:border-neutral-200 dark:hover:border-neutral-800 hover:text-neutral-800 dark:hover:text-white inline-flex items-center rounded-t-lg py-2 px-3'
    },

    saveChanges (close) {
      this.updateSheetName(this.sheet, this.newName)
      this.updateSheetType(this.sheet, this.newType)
      close()
    },

    parseSheet (sheet) {
      this.newName = sheet.name
      this.newType = sheet.typeFilter.value
    }
  }
}
</script>
