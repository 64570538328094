import { dateToInteger } from './utils.js'
import DETECTORS from './detectors.js'
import Transaction from './transaction.js'
import Posting from './posting.js'
import Account from './account.js'

class Block {
  constructor (content) {
    this.content = content
  }
}

Block.from = (line) => {
  return new Block(line)
}

class Alias {
  constructor (shortcut, replacement) {
    this.shortcut = shortcut
    this.replacement = replacement
  }
}

Alias.from = (line) => {
  const splat = line.replace('alias', '').trim().split('=')

  return new Alias(splat[0], splat[1])
}

class Recurrence {
  constructor (frequencyDescription, processor) {
    this.frequencyDescription = frequencyDescription
    this.processor = processor
    this.transaction = new Transaction(processor)
    this.name = `Budget (${frequencyDescription})`

    const testStart = /[from|since]+\s(\d{4}(\/\d{2})?)/.exec(frequencyDescription)
    const testEnd = /[to|until]+\s(\d{4}(\/\d{2})?)/.exec(frequencyDescription)

    if (testStart) {
      const startSplat = testStart[1].split(/\//g)
      startSplat[1] = typeof startSplat[1] !== 'undefined' ? parseInt(startSplat[1]) - 1 : 0
      this.startAt = dateToInteger(new Date(...startSplat))
    }

    if (testEnd) {
      const endSplat = testEnd[1].split(/\//g)
      endSplat[1] = typeof endSplat[1] !== 'undefined' ? parseInt(endSplat[1]) - 1 : 0
      this.endAt = dateToInteger(new Date(...endSplat))
    }
  }

  update (line) {
    if (DETECTORS.comment.test(line)) {
      const nameMatch = line.trim().match(DETECTORS.metadata('Name'))

      if (nameMatch) {
        this.name = nameMatch[1].trim()
      }
    } else {
      const postings = Posting.fromLine(line, this.transaction, this.processor)

      this.addPostings(postings)
      this.parseName()
    }
  }

  parseName () {
    const postingWithComment = this.getPostings().find((posting) => posting.elided) || [...this.getPostings()].reverse().find((posting) => posting.comment)

    if (postingWithComment && postingWithComment.comment) {
      this.name = `${postingWithComment.comment} (${this.frequencyDescription})`
    }
  }

  getPostings () {
    return this.transaction.postings
  }

  addPostings (postings) {
    this.transaction.postings.push(...postings)
  }

  isForDate (date) {
    return (
      !this.startAt ||
      date >= this.startAt
    ) && (
      !this.endAt ||
      date < this.endAt
    )
  }
}

Recurrence.from = (line, processor) => {
  const frequencyDescription = line.replace(/~\s+/, '').trim()

  return new Recurrence(frequencyDescription, processor)
}

class Capture {
  constructor (shortcut, replacement) {
    this.shortcut = shortcut
    this.replacement = replacement
  }
}

Capture.from = (line) => {
  const splat = line.split(/\s\s+|\t/)

  return new Capture(splat[2], splat[1])
}

class Bucket {
  constructor (name) {
    this.name = name
  }
}

Bucket.from = (line) => {
  const name = line.split(/\s+/)[1].trim()

  return new Bucket(name)
}

class Processor {
  constructor (input) {
    this.transactions = []
    this.recurrences = []
    this.commands = []
    this.currentItem = null
    this.bucket = null
    this.aliases = {}
    this.captures = {}
    this.accounts = {}
    this.logs = []
    this.input = input
  }

  processLine (line) {
    const trimmedLine = line.trim()

    if (DETECTORS.transactionStart.test(line)) {
      const transaction = this.addTransaction(trimmedLine)
      this.currentItem = transaction
      this.log(transaction)
    } else if (DETECTORS.command.test(line)) {
      const command = this.runCommand(trimmedLine)
      this.log(command)
    } else if (DETECTORS.account.test(line)) {
      const account = this.addAccount(trimmedLine)
      this.currentItem = account
      this.log(account)
    } else if (DETECTORS.recurring.test(line)) {
      const recurrence = this.addRecurrence(trimmedLine)
      this.currentItem = recurrence
      this.log(recurrence)
    } else if (trimmedLine.length) {
      if (this.currentItem) {
        this.currentItem.update(trimmedLine)
      } else {
        const block = this.addBlock(trimmedLine)
        this.log(block)
      }
    }
  }

  addTransaction (line) {
    const transaction = Transaction.from(line, this)

    this.transactions.push(transaction)

    return transaction
  }

  addAccount (line) {
    const account = Account.from(line, this)

    this.accounts[account.key] = account

    return account
  }

  runCommand (line) {
    if (/alias/.test(line)) {
      return this.addAlias(line)
    } else if (/bucket/.test(line)) {
      return this.addBucket(line)
    } else if (/capture/.test(line)) {
      return this.addCapture(line)
    }
  }

  reconcile (account) {
    this.transactions.forEach((t) => t.reconcile(account))
  }

  addAlias (line) {
    const alias = Alias.from(line)

    this.aliases[alias.shortcut] = alias.replacement

    return alias
  }

  addBucket (line) {
    const bucket = Bucket.from(line)

    this.bucket = bucket.name

    return bucket
  }

  addBlock (line) {
    const block = Block.from(line)

    return block
  }

  applyAliasAndCapture (account) {
    if (typeof this.aliases[account] !== 'undefined') {
      return this.aliases[account]
    }

    for (const capture in this.captures) {
      account = account.replace(capture, this.captures[capture])
    }

    return account
  }

  addCapture (line) {
    const capture = Capture.from(line)

    this.captures[capture.shortcut] = capture.replacement

    return capture
  }

  addRecurrence (line) {
    const recurrence = Recurrence.from(line, this)

    this.recurrences.push(recurrence)

    return recurrence
  }

  buildRecurrences () {
    this.recurrences.forEach((recurrence) => {
      const date = new Date(new Date().toDateString())

      date.setMonth(date.getMonth() + 1)
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)

      for (var i = 0; i < 12; i++) {
        if (recurrence.isForDate(dateToInteger(date))) {
          const transaction = this.addTransaction(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}  ! ${recurrence.frequencyDescription}`)

          transaction.addPostings(recurrence.getPostings())
        }

        date.setMonth(date.getMonth() + 1)
      }
    })
  }

  log (item) {
    this.logs.push(item)
  }
}

Processor.parse = (input) => {
  const lines = input.split('\n')
  const processor = new Processor(input)

  lines.forEach((line) => processor.processLine(line))

  if (processor.bucket) {
    processor.reconcile(processor.bucket)
  }

  processor.buildRecurrences()

  return processor
}

Processor.simpleParse = (input) => {
  const lines = input.split('\n')
  const processor = new Processor(input)

  lines.forEach((line) => processor.processLine(line))

  return processor
}

export default Processor
