class Token {
  constructor (type, text) {
    this.type = type
    this.text = text
  }

  append (text) {
    this.text += text
  }

  setText (text) {
    this.text = text
  }

  toString () {
    return this.text
  }

  toHTML () {
    return this.toString().replace(/\s/, '&nbsp;')
  }
}

Token.CONJUNCTION = 'conjunction'
Token.SEPARATOR = 'separator'
Token.VALUE = 'value'
Token.LABEL = 'label'

class ValueToken extends Token {
  constructor (text) {
    super(Token.VALUE, text)
    this.quotes = 'none'
  }

  openQuotes () {
    this.quotes = 'open'
  }

  closeQuotes () {
    this.quotes = 'close'
  }

  setLabel (token) {
    this.label = token
  }

  isQuotesOpen () {
    return this.quotes === 'open'
  }

  isQuotesClosed () {
    return this.quotes === 'close'
  }

  setText (text) {
    if (text.indexOf(' ') !== -1) {
      this.quotes = 'close'
    } else {
      this.quotes = 'none'
    }

    this.text = text
  }

  toString () {
    if (this.isQuotesClosed()) {
      return `"${this.text}"`
    } else if (this.isQuotesOpen()) {
      return `"${this.text}`
    } else {
      return this.text
    }
  }
}

class LabelToken extends Token {
  constructor (text) {
    super(Token.LABEL, text)
  }

  setValue (token) {
    this.value = token
  }
}

class ConjunctionToken extends Token {
  constructor (text) {
    super(Token.CONJUNCTION, text)
  }
}

class SeparatorToken extends Token {
  constructor (text) {
    super(Token.SEPARATOR, text)
  }
}

Token.conjunction = (str) => {
  return new ConjunctionToken(str)
}

Token.separator = (str) => {
  return new SeparatorToken(str)
}

Token.value = (str) => {
  return new ValueToken(str)
}

Token.label = (str) => {
  return new LabelToken(str)
}

class Tokenizer {
  constructor (str) {
    this.str = str || ''
    this.tokenMap = {}
    this.tokens = []
  }

  insertAfter (token, str) {
    const index = this.tokens.indexOf(token) + 2

    const t = new Tokenizer(str)

    t.parse()

    this.tokens.splice(index, 0, ...t.tokens)

    this.str = this.tokens.map((t) => t.toString()).join('')
    this.parse()
  }

  parse () {
    const str = this.str
    const tokenMap = {}
    const tokens = []
    const chars = str.split('')

    for (var i = 0; i < chars.length; i++) {
      const char = chars[i]
      const lastToken = tokenMap[i - 1]

      if (char === ':' || char === '=') {
        if (lastToken && lastToken.type === Token.VALUE) {
          lastToken.append(char)
          tokenMap[i] = lastToken
        } else {
          tokenMap[i] = Token.conjunction(char)
          tokens.push(tokenMap[i])
        }
      } else if (char === ' ') {
        if (lastToken && lastToken.type === Token.SEPARATOR) {
          lastToken.append(char)
          tokenMap[i] = lastToken
        } else if (lastToken && lastToken.type === Token.VALUE && lastToken.quotes === 'open') {
          lastToken.append(char)
          tokenMap[i] = lastToken
        } else {
          tokenMap[i] = Token.separator(char)
          tokens.push(tokenMap[i])
        }
      } else if (char === '"') {
        if (lastToken && lastToken.type === Token.VALUE) {
          lastToken.closeQuotes()
          tokenMap[i] = lastToken
        } else if (lastToken && lastToken.type === Token.CONJUNCTION) {
          tokenMap[i] = Token.value('')
          tokenMap[i].setLabel(tokens[tokens.length - 2])
          tokenMap[i].openQuotes()
          tokens.push(tokenMap[i])
        }
      } else if (lastToken && lastToken.type === Token.CONJUNCTION) {
        tokenMap[i] = Token.value(char)
        tokenMap[i].setLabel(tokens[tokens.length - 2])
        tokens.push(tokenMap[i])
      } else if (lastToken && lastToken.type === Token.SEPARATOR) {
        tokenMap[i] = Token.label(char)
        tokens.push(tokenMap[i])
      } else if (lastToken) {
        lastToken.append(char)
        tokenMap[i] = lastToken
      } else {
        tokenMap[i] = Token.label(char)
        tokens.push(tokenMap[i])
      }
    }

    this.tokenMap = tokenMap
    this.tokens = tokens
  }
}

export {
  Token,
  ValueToken,
  LabelToken,
  Tokenizer
}
