const DETECTORS = {
  account: /^account/,
  transactionStart: /^\d/,
  comment: /^[;#%|*]/,
  metadata (attr) { return new RegExp(`;\\s${attr}:\\s+?(.+)$`) },
  command: /^(alias|assert|bucket|capture|check|comment|commodity|define|payee|expr\ninclude|payee|tag|test|year)/,
  blockCommandStart: /^(apply\saccount|apply\sfixed|apply\stag)/,
  blockCommandEnd: /^end/,
  recurring: /^~/
  // two spaces or a tab for line delineator
}

export default DETECTORS
