<template>
  <div
    v-for="currency in currencies"
    :key="`account-balance-${currency}`"
    class="px-5 pb-5"
  >
    <pre class="mb-5 text-neutral-400"><span
    v-for="line in linesFor(currency)"
    :key="line"
    >{{ pad(formatAmount(line.sum, line.currency), 16) }} {{ pad('', line.indent) }}|- <a
href="javascript:;"
    class="hover:underline hover:text-indigo-600 dark:hover:text-white"
@click="app.zoom(line.account, line.transactions)"
    >{{ lastSplat(line.account) }}</a><br></span>{{ totalFor(currency) }}</pre>
  </div>
</template>

<script>
import { pad, formatAmount, postingsForAccount, sumPostingsForCurrency, isTransactionForAccount } from '@/parse-and-play/utils'

export default {
  props: ['app', 'sheet', 'transactions'],

  computed: {
    postings () {
      return this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
    },

    accounts () {
      const accs = [...new Set(this.postings.map((p) => p.account))].sort()
      const explodedAccounts = []

      for (var i = 0; i < accs.length; i++) {
        const splat = accs[i].split(':')

        for (var n = 0; n < splat.length; n++) {
          const fullAccount = splat.slice(0, n + 1).join(':')

          if (explodedAccounts.indexOf(fullAccount) === -1) {
            explodedAccounts.push(fullAccount)
          }
        }
      }

      return explodedAccounts
    },

    currencies () {
      return [...new Set(this.postings.map((p) => p.currency))]
    }
  },

  methods: {
    lastSplat (account) {
      const splat = account.split(':')

      return splat[splat.length - 1]
    },

    totalFor (currency) {
      const total = sumPostingsForCurrency(this.postings, currency)

      return `${pad('', 16, '-')}\n${pad(formatAmount(total, currency), 16)}`
    },

    pad,
    formatAmount,

    linesFor (currency) {
      const lines = []

      for (var i = 0; i < this.accounts.length; i++) {
        const account = this.accounts[i]
        const postings = postingsForAccount(this.postings, account)
        const sum = sumPostingsForCurrency(postings, currency)
        const anyNestedAccountsWithAmounts = this.accounts
          .filter((a) => a.indexOf(`${account}:`) === 0)
          .map((a) => postingsForAccount(this.postings, a))
          .filter((p) => Math.abs(sumPostingsForCurrency(p, currency)).toLocaleString() !== '0')
          .length

        if (Math.abs(sum).toLocaleString() !== '0' || anyNestedAccountsWithAmounts) {
          const indent = (account.split(':').length - 1) * 1
          const transactions = this.transactions.filter((t) => isTransactionForAccount(t, account))

          lines.push(new Line(sum, currency, indent, account, transactions))
        }
      }

      return lines
    }
  }
}

class Line {
  constructor (sum, currency, indent, account, transactions) {
    this.sum = sum
    this.currency = currency
    this.indent = indent
    this.account = account
    this.transactions = transactions
  }
}
</script>
