<template>
  <Panel
    title="Export"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <form
        class="grid grid-cols-1 gap-y-2"
        @submit.prevent="download"
      >
        <p class="my-5">
          Export all of the transactions for <strong class="text-white">{{ app.identity.name }}</strong> into a single, unified file.
        </p>

        <div class="col-span-full mt-5">
          <button
            type="submit"
            class="block w-full rounded-md bg-indigo-500 px-3 py-2 text-sm text-white"
          >
            Export as a Plain Text Accounting .txt file
          </button>
        </div>
      </form>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },
  props: ['app'],
  methods: {
    download () {
      this.app.commands.download(this.app.identity)
    }
  }
}
</script>
