<template>
  <div class="w-full bg-white dark:bg-neutral-900 border-b border-neutral-300 dark:border-neutral-800">
    <div class="lg:flex">
      <div class="pointer-events-none items-center px-3 pr-0 hidden lg:flex">
        <svg
          class="h-5 w-5 text-neutral-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="flex-1 block w-full">
        <div>
          <span
            class="hidden lg:inline text-sm dark:text-neutral-400 absolute p-3 pointer-events-none"
          ><span
            v-for="token in tokens"
            :key="`token-${token}-${Math.random()}`"
          ><span
            v-if="token.type === 'value'"
            class="text-indigo-400"
            v-html="token.toHTML()"
          /><span
            v-else
            class="opacity-0"
            v-html="token.toHTML()"
          /></span></span>
          <input
            ref="input"
            v-model="newFilter"
            type="text"
            class="block w-full text-sm text-neutral-600 dark:text-neutral-400 border-0 bg-transparent p-3 focus:ring-transparent placeholder:text-neutral-600 focus:outline-0"
            placeholder="Filters..."
            @focus="focus"
            @blur="blur"
            @change="updateSheetFilters(sheet, newFilter)"
            @keyup.prevent="keyup"
            @keyup.enter.prevent="enter"
            @keydown.up.prevent="changeSelection(-1)"
            @keydown.down.prevent="changeSelection(+1)"
          >
          <div
            class="absolute left-0 right-0 z-50 max-h-72 overflow-y-auto text-neutral-600 bg-neutral-100 dark:bg-neutral-900 drop-shadow-xl"
          >
            <ul
              v-if="suggestions.length"
              class="text-sm border-t border-1 border-neutral-300 dark:border-neutral-800"
            >
              <li
                v-for="(suggestion, index) in suggestions"
                :key="`suggestion-${suggestion}`"
                class="border-b border-neutral-300 dark:border-neutral-800"
              >
                <a
                  href="javascript:;"
                  class="block p-3 hover:bg-white hover:dark:bg-neutral-800 dark:hover:text-white"
                  :class="highlightedSuggestion(index) ? 'bg-white dark:bg-neutral-800 dark:text-white' : ''"
                  @click="applySuggestion(suggestion)"
                >
                  {{ suggestion }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DatePicker
        :sheet="sheet"
        :update-sheet-date-period="updateSheetDatePeriod"
        :update-sheet-date-value="updateSheetDateValue"
        class="border-t border-neutral-800 lg:border-t-0"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from '@/app/components/date-picker/component.vue'
import { Tokenizer } from '@/app/components/search/tokenizer.js'

export default {
  components: {
    DatePicker
  },

  props: ['sheet', 'accountNames', 'payeeNames', 'updateSheetFilters', 'updateSheetDatePeriod', 'updateSheetDateValue'],

  data () {
    return {
      newFilter: '',
      selectedIndex: -1,
      suggestions: [],
      currentToken: null
    }
  },

  computed: {
    tokenMap () {
      return this.tokenizer.tokenMap
    },

    tokens () {
      return this.tokenizer.tokens
    },

    tokenizer () {
      const t = new Tokenizer(this.newFilter)
      t.parse()
      return t
    }
  },

  watch: {
    sheet () {
      this.updateNewFilter(this.sheet)
    }
  },

  mounted () {
    this.updateNewFilter(this.sheet)
  },

  methods: {
    blur () {
      setTimeout(() => {
        this.selectedIndex = -1
        this.suggestions = []
      }, 20)
    },

    enter () {
      const suggestion = this.suggestions[this.selectedIndex]

      if (suggestion) {
        this.applySuggestion(suggestion)
      } else {
        this.suggestions = []
        this.updateSheetFilters(this.sheet, this.newFilter)
      }
    },

    focus () {
      this.selectedIndex = -1
      this.updateSuggestions()
    },

    keyup () {
      this.updateSuggestions()
    },

    updateNewFilter (sheet) {
      this.newFilter = sheet.filters.join(' ')
    },

    applySuggestion (suggestion) {
      if (this.currentToken) {
        if (this.currentToken.type === 'label') {
          this.currentToken.setText(`${suggestion}="`)
        } else {
          this.currentToken.setText(suggestion)
        }

        if (this.currentToken.type === 'value') {
          this.currentToken.quotes = 'close'
        }

        this.newFilter = this.tokens.map((t) => t.toString()).join('')
      } else {
        this.newFilter = suggestion
      }

      this.selectedIndex = -1
      this.updateSuggestions()
      this.updateSheetFilters(this.sheet, this.newFilter)
    },

    highlightedSuggestion (selectedIndex) {
      return this.selectedIndex === selectedIndex
    },

    changeSelection (delta) {
      if (delta > 0 && this.selectedIndex + delta < this.suggestions.length) {
        this.selectedIndex += delta
      }

      if (delta < 0 && this.selectedIndex + delta >= -1) {
        this.selectedIndex += delta
      }
    },

    updateCurrentToken () {
      this.currentToken = this.tokenMap[this.$refs.input.selectionStart - 1]
    },

    updateSuggestions () {
      this.updateCurrentToken()

      const q = (this.currentToken ? this.currentToken.text.toLowerCase() : '')
      const booleanable = ['cleared', 'hasTxnId']

      let suggestions = []

      if (this.currentToken && this.currentToken.type === 'value') {
        if (booleanable.filter((b) => b.indexOf(this.currentToken.label.toString()) !== -1).length) {
          suggestions = [
            'true',
            'false'
          ]
        } else if (this.currentToken.label.toString() === 'account') {
          suggestions = this.accountNames
        } else if (this.currentToken.label.toString() === 'payee') {
          suggestions = this.payeeNames
        }

        if (q.length) {
          suggestions = suggestions
            .filter((i) => i.toLowerCase().indexOf(q) !== -1 && i.toLowerCase() !== q)
        }
      } else if (!this.currentToken || this.currentToken.type !== 'conjunction') {
        suggestions = [
          'account',
          'cleared',
          'currency',
          'hasTxnId',
          'payee'
        ]

        if (q.length) {
          suggestions = suggestions
            .filter((suggestion) => suggestion.indexOf(q) !== -1 && suggestion !== q)
        }
      }

      this.suggestions = suggestions
    }
  }
}
</script>
