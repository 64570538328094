import Processor from '@/parse-and-play/processor.js'

class PlainText {
  async process (input) {
    const processor = Processor.simpleParse(input)

    return {
      accounts: Object.values(processor.accounts).map((account) => ({
        name: account.key,
        address: account.address
      })),
      transactions: processor.transactions,
      periodics: processor.recurrences.map((recurrence) => {
        return {
          name: recurrence.name,
          frequency: recurrence.frequencyDescription.toLowerCase(),
          startAt: recurrence.startAt,
          endAt: recurrence.endAt,
          referenceId: recurrence.transaction.referenceId,
          comments: recurrence.transaction.comments || [],
          postings: recurrence.transaction.postings.map((posting) => {
            return {
              account: posting.account,
              amount: posting.amount,
              comment: posting.comment,
              currency: posting.currency,
              elided: posting.elided
            }
          })
        }
      })
    }
  }
}

export default PlainText
