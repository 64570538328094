<template>
  <Panel
    v-if="transaction"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <div
        id="transaction-invoice"
        class="print:text-sm"
      >
        <button
          class="float-left print:hidden rounded-md bg-indigo-500 px-3 py-2 text-sm text-white dark:text-neutral-700"
          @click="print"
        >
          Print
        </button>

        <p class="text-right">
          <strong v-if="incomeSummary">INVOICE</strong>
          <strong v-else>RECEIPT</strong>
          <br><br>
          <span v-html="app.queries.toAccountAddress(app.identity, transaction)" />
        </p>

        <p>
          <span v-if="transaction.referenceId">
            Invoice No: {{ transaction.referenceId }}<br>
          </span>
          Date: {{ app.queries.prettyDate(transaction.date) }}<br>
        </p>

        <br>

        <p
          v-if="app.queries.transactionCleared(transaction)"
          class="float-right"
        >
          PAID
        </p>

        <p>
          <strong><span v-if="incomeSummary">Bill To</span><span v-else>Received From</span>:</strong>
          <a
            href="javascript:;"
            class="print:hidden inline-block ml-3 text-indigo-500"
            @click="incomeSummary = !incomeSummary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="size-4 inline-block"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </a><br>
          <span v-html="app.queries.fullPayeeAddress(app.identity, transaction)" />
        </p>

        <br><br>

        <table class="w-full">
          <thead>
            <tr>
              <th class="text-left">
                Items & Description
              </th>
              <th class="text-right">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="posting in postings"
              :key="posting"
            >
              <td v-html="posting.comment || app.queries.fullAccountName(app.identity, posting.account)" />
              <td class="text-right">
                {{ app.queries.formatAmount(-posting.amount, posting.currency) }}
              </td>
            </tr>
            <tr>
              <th colspan="2">
                <br>
              </th>
            </tr>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td class="text-right">
                <strong>{{ total }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },

  props: ['app'],

  data () {
    return {
      incomeSummary: true
    }
  },

  computed: {
    transaction () {
      return this.app.queries.findTransactionForIdentity(this.app.identity.id, this.$route.params.transaction_id)
    },

    postings () {
      const cat = this.incomeSummary ? 'income' : 'expenses'

      return this.app.queries[cat](this.transaction.postings)
    },

    total () {
      let t = this.app.queries.transactionTotal(this.transaction)

      if (this.incomeSummary && t.indexOf('-') !== -1) {
        t = t.replace(/^-/, '')
      } else if (!this.incomeSummary && t.indexOf('-') === -1) {
        t = `-${t}`
      }

      return t
    }
  },

  methods: {
    print () {
      window.print()
    }
  }
}
</script>

<style lang="scss">
  @media print {
    .main-content {
      display: none
    }
  }
</style>
