import { matchesAccount, matchesAccounts, parseDate } from './utils.js'
import DETECTORS from './detectors.js'
import Posting from './posting.js'

const groupBy = (items, key) => {
  return items.reduce((acc, item) => {
    (acc[item[key]] = acc[item[key]] || []).push(item)
    return acc
  }, {})
}

class Transaction {
  constructor (processor) {
    this.processor = processor
    this.comments = []
    this.postings = []
    this.date = null
    this.referenceId = null
    this.payee = null
    this.status = Transaction.STATUSES.PENDING
  }

  update (line) {
    if (DETECTORS.comment.test(line)) {
      this.comments.push(line)
    } else if (DETECTORS.transactionStart.test(line)) {
      this.applyTitle(line)
    } else {
      const postings = Posting.fromLine(line, this, this.processor)
      this.addPostings(postings)
    }
  }

  addPostings (postings) {
    this.postings.push(...postings)
  }

  applyTitle (line) {
    const splat = line.split(/\s+/g)

    this.date = parseDate(splat.shift())

    const status = splat[0].trim()

    if (
      status === Transaction.STATUSES.PENDING ||
      status === Transaction.STATUSES.CLEARED
    ) {
      this.status = status
      splat.shift()
    }

    const transactionIdSplat = /^\(([A-Za-z0-9#-]+)\)$/.exec(splat[0])

    if (transactionIdSplat) {
      this.referenceId = transactionIdSplat[1]
      splat.shift()
    }

    this.payee = splat.join(' ').trim()
  }

  sum (account) {
    const summary = {}

    this.postings.forEach((posting) => {
      if (!matchesAccount(posting.account, account)) return

      if (typeof summary[posting.account] === 'undefined') {
        summary[posting.account] = {}
      }

      if (typeof summary[posting.account][posting.currency] === 'undefined') {
        summary[posting.account][posting.currency] = 0
      }

      if (typeof posting.amount !== 'undefined') {
        summary[posting.account][posting.currency] += posting.amount
      }
    })

    return summary
  }

  sumAccounts (accounts) {
    const summary = {}

    this.postings.forEach((posting) => {
      if (!matchesAccounts(posting.account, accounts)) return

      if (typeof summary[posting.account] === 'undefined') {
        summary[posting.account] = {}
      }

      if (typeof summary[posting.account][posting.currency] === 'undefined') {
        summary[posting.account][posting.currency] = 0
      }

      if (typeof posting.amount !== 'undefined') {
        summary[posting.account][posting.currency] += posting.amount
      }
    })

    return summary
  }

  reconcileSummary () {
    const summary = {}

    this.postings.forEach((posting) => {
      if (typeof summary[posting.currency] === 'undefined') {
        summary[posting.currency] = 0
      }

      summary[posting.currency] += posting.amount
    })

    return summary
  }

  buildReconciliation (account, comment) {
    const summary = this.reconcileSummary()
    const postings = []

    for (const currency in summary) {
      if (summary[currency] !== 0) {
        postings.push(new Posting(summary[currency] * -1, currency, account, Posting.ELIDED, null, comment))
      }
    }

    return postings
  }

  getPostings (filters = []) {
    const postings = this.postings

    if (filters.length === 0) {
      return postings
    }

    const groups = groupBy(filters, (f) => f.type)

    return postings.filter((posting) => {
      for (const type in groups) {
        const typeFilters = groups[type]

        if (typeFilters.filter((f) => f.filter()(posting)).length) {
          continue
        }

        return false
      }

      return true
    })
  }

  reconcile (account) {
    const postings = this.buildReconciliation(account)

    this.postings.push(...postings)
  }

  get cleared () {
    return this.status === Transaction.STATUSES.CLEARED
  }
}

Transaction.from = (line, processor) => {
  const transaction = new Transaction(processor)

  transaction.update(line)

  return transaction
}

Transaction.STATUSES = {
  PENDING: '!',
  CLEARED: '*'
}

export default Transaction
