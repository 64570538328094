class Posting {
  constructor (amount, currency, account, elided, value, comment) {
    this.amount = amount
    this.currency = currency
    this.account = account
    this.elided = elided || false
    this.value = value
    this.comment = comment

    if (!this.account) {
      throw new Error(`No account supplied: ${amount} ${currency} ${elided} ${value}`)
    }
  }
}

const parseDynamicAmount = (str) => {
  let currency
  let amount
  let lastAction

  str = str.slice(1, str.length - 1)

  str.split(/\s/).forEach((item) => {
    const possibleCurrency = item.replace(/\d|,|\.|-/g, '').trim()
    const possibleNumber = item.replace(/[^\d-.]/g, '').trim()

    if (item === '+' || item === '-' || item === '/' || item === '*') {
      lastAction = item
      return
    }

    if (possibleCurrency) {
      currency = possibleCurrency

      if (!possibleNumber.length) { return }
    }

    const num = parseFloat(possibleNumber)

    if (!lastAction) {
      amount = num
    } else if (lastAction === '+') {
      amount = amount + num
    } else if (lastAction === '-') {
      amount = amount - num
    } else if (lastAction === '/') {
      amount = amount / num
    } else if (lastAction === '*') {
      amount = num * amount
    }
  })

  return {
    amount,
    currency
  }
}

const parseDetails = (line, transaction, processor) => {
  let currency
  let amount

  const commentSplat = line.split(';')
  const accountSplat = commentSplat[0].split(/\s\s+|\t/)
  const account = accountSplat[0].trim()

  if (accountSplat[1]) {
    const commoditySplat = accountSplat[1].split('@')[0].trim().split(/\s|\t/)

    if (commoditySplat.length === 1) {
      currency = (commoditySplat[0] || '').replace(/\d|,|\.|-/g, '').trim()
      amount = parseFloat(commoditySplat[0].replace(/[^\d-.]/g, '').trim())
    } else if (commoditySplat.length === 2) {
      amount = parseFloat(commoditySplat[0])
      currency = commoditySplat[1]
    } else {
      const dynamicDetails = parseDynamicAmount(commoditySplat.join(' '))
      amount = parseFloat(dynamicDetails.amount)
      currency = dynamicDetails.currency
    }
  }

  return {
    account: processor.applyAliasAndCapture(account),
    currency,
    amount,
    comment: (commentSplat[1] || '').trim()
  }
}

Posting.ELIDED = true
Posting.fromLine = (line, transaction, processor) => {
  const details = parseDetails(line, transaction, processor)

  if (details.currency) {
    return [new Posting(details.amount, details.currency, details.account, null, null, details.comment)]
  } else if (details.account) {
    return transaction.buildReconciliation(details.account, details.comment)
  }
}

export default Posting
