<template>
  <Panel
    v-if="zoomer.transactions.length"
    :title="zoomer.title"
    :back="back"
  >
    <template #content>
      <div class="mx-auto">
        <Register
          :app="app"
          :sheet="null"
          :transactions="zoomer.transactions"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'
import Register from '@/app/views/transactions/types/register.vue'

export default {
  components: {
    Panel,
    Register
  },

  props: ['app', 'zoomer'],

  methods: {
    back () {
      this.app.zoom(null, [])
    }
  }
}
</script>
