<template>
  <div ref="chartWrapper" />
</template>

<script>
import Chart from 'chart.js/auto'
import { postingsForAccount, sumPostingsForCurrency } from '@/parse-and-play/utils.js'

export default {
  props: ['sheet', 'transactions'],
  computed: {
    chartData () {
      // TODO: Should be chart per currency
      const postings = this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
      const currency = postings[0] ? postings[0].currency : null
      const path = this.path ? new RegExp(`^${this.path}:`) : /^/
      const accounts = postings
        .filter((posting) => path.exec(posting.account))
        .map((posting) => this.path ? [this.path, posting.account.replace(path, '').split(':')[0]].join(':') : posting.account.replace(path, '').split(':')[0])
      const uniqueAccounts = [...new Set(accounts)]
      const labels = uniqueAccounts
      const data = uniqueAccounts.map((account) => {
        return Math.abs(sumPostingsForCurrency(postingsForAccount(postings, account), currency))
      })

      return {
        labels,
        datasets: [{
          label: currency,
          data
        }]
      }
    }
  },
  watch: {
    chartData () {
      this.$nextTick(() => this.makeChart())
    }
  },
  mounted () {
    this.makeChart()
  },
  methods: {
    makeChart () {
      const data = this.chartData
      const canvas = document.createElement('canvas')

      canvas.className = 'block mx-auto mt-8 mb-5'

      this.$refs.chartWrapper.innerHTML = ''
      this.$refs.chartWrapper.appendChild(canvas)

      const chart = new Chart(canvas, {
        type: 'polarArea',
        data,
        options: {
          responsive: true,
          animation: {
            duration: 0
          },
          onClick: (evt) => {
            const elements = chart.getElementsAtEventForMode(evt, 'index', { intersect: true }, false)
            const index = elements[0].index
            const label = chart.data.labels[index]
            // const data = chart.data.datasets[0].data[index]

            this.prefix = label
          }
        }
      })
    }
  }
}
</script>
